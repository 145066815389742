<template>
  <div class="page-main" :class="{ 'page-main-whitelabel': whitelabel }">
    <Header />
    <div
      class="page-main-whitelabel--main"
      :style="whitelabelStyles"
    >
      <div class="page-main-whitelabel--bg" :class="{ 's7-bg': isS7, 'etagi-bg': isEtagi }"></div>
      <a
        class="link-pravila-programmy"
        target="_blank"
        href="https://www.s7.ru/ru/s7priority/pravila-programmy/?from=s7-sutochno"
        v-if="isS7"
        @click="onS7BannerCick"
      ></a>
      <MainWrapper class="page-main-whitelabel--main-inner" />
    </div>
    <ModalLight v-model="s7Modal">
      <div class="s7-modal">
        <h6 class="s7-modal__title">Получите 2 мили за каждые потраченные 20 рублей</h6>
        <ul>
          <li>
            Акция действует на бронирования, которые были созданы с 01 июня по 31 августа 2024 года и период проживания в арендуемом помещении с 01 июня 2024 года по 30 сентября 2024 года.
          </li>
          <li>
            Введите ваш номер участника S7 Priority в процессе бронирования.
          </li>
          <li>Мили S7 Priority будут начислены после завершения проживания.</li>
        </ul>
        <UIButton @click="openS7Rules">
          Посмотреть правила и условия
        </UIButton>
      </div>
    </ModalLight>
    <Footer v-if="!isNotShowFooter" />
  </div>
</template>

<script>
import { Header } from "@sutochno/header";
import { event } from "vue-gtag";
import baseParams from "@/composables/gtag/partners/s7/baseParams";
// import { Header } from "@/components/Header";
import ModalLight  from "@/components/UIKit/UIModalLight.vue";
import UIButton  from "@/components/UIKit/UIButton.vue";
import MainWrapper from "@/components/desktop/Main/";
import { Footer } from "@sutochno/footer";
import { Main, Cian } from "@/utils/gtm/";
import useMetaMain from '@/composables/meta/main';
import { mapState } from "vuex";

export default {
  components: {
    Header,
    ModalLight,
    UIButton,
    MainWrapper,
    Footer,
  },
  setup() {
    useMetaMain();
  },
  data() {
    return {
      headerHeight: null,
      s7Modal: false,
    };
  },
  computed: {
    ...mapState("user", ["whitelabel"]),
    isCian() {
      return this.whitelabel && this.whitelabel.name === "cian";
    },
    isS7() {
      return this.whitelabel && this.whitelabel.name === "s7";
    },
    isEtagi() {
      return this.whitelabel && this.whitelabel.name === "etagi";
    },
    isNotShowFooter() {
      return this.whitelabel && (
        this.whitelabel.name === "utair"
        || this.whitelabel.name === "aeroflot"
        || this.whitelabel.name === "leveltravel"
        || this.whitelabel.name === "pobeda"
        || this.whitelabel.name === "licard"
      );
    },
    whitelabelStyles() {
      if (!this.headerHeight) {
        return null;
      }
      return `height: calc(100vh - ${this.headerHeight}px)`;
    }
  },
  mounted() {
    this.setheaderHeight();
    if (this.isCian) {
      Cian.gtmPartnerPageview({
        pageType: "Home"
      });
    }
    Main.gtmDataPage();

    if(this.isS7) {
      event("load_bot", {
        ...baseParams,
        step: "main",
      })
    }
  },
  methods: {
    openS7Rules(){
      window.open("https://www.s7.ru/ru/offers/s7priority/fly-again/");
    },
    setheaderHeight() {
      const header = document.querySelector('.header-wl-wrap');
      if (header) {
        this.headerHeight = header.offsetHeight;
      }
    },
    onS7BannerCick() {
      event("select_promotion", {
        ...baseParams,
        step: "banner",
        promotion_name: "click_banner",
        items: [
          {
            item_id: "banner_lottery",
            location_id: "sutochno_main"
          }
        ]
      })
    }
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1280px) {
  :deep(.sc-footer-container) {
    width: 1180px !important;
  }
}
.page-main-whitelabel {
  height: 100vh;
  &--main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: calc(100vh - 50px);
    position: relative;
    :deep(.title-block) {
      margin-top: 0;
      h1, p {
        color: #ffffff;
      }
    }
    :deep(.search-widget-select-city) {
      span.title {
        color: #ffffff;
      }
    }
    :deep(.btn-search-main) {
      border-radius: 50px !important;
    }
  }
  &--bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000 url("~/assets/img/main/interior-big.webp") 50% 100% no-repeat;
    background-size: cover;
    &.s7-bg {
      // background: #000 url("~/assets/img/main/s7-seachbanner.webp") 50% 100% no-repeat;
      background: #000 url("~/assets/img/main/s7-seachbanner-2.webp") 50% 100% no-repeat;
      background-size: cover;
      &:after {
        display: none;
      }
    }
    &.etagi-bg {
      background: #000 url("~/assets/img/main/etagi.webp") 50% 100% no-repeat;
      background-size: cover;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: .5;
    }
  }
  &--main-inner {
    position: relative;
    z-index: 10;
    margin-bottom: 250px;
    @media screen and (max-height: 840px) {
      margin-bottom: 100px;
    }
    @media screen and (max-height: 600px) {
      margin-bottom: 0;
    }
  }
  .s7-modal{
    max-width: 600px;
    padding: 20px;

    ul{
      padding-left: 16px;
      margin-bottom: 15px;
    }
    li +li {
      margin-top: 10px;
    }
  }
}
.link-pravila-programmy {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 41vh;
  z-index: 5;
}
</style>
